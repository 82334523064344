import React, { useEffect, useRef, useState } from "react";
import AuthorTable from "../components/AuthorTable";
import AuthorEditModal from "../components/AuthorEditModal";
import AuthorAddModal from "../components/AuthorAddModal";
import { useParams } from "react-router-dom";
import axios from "axios";
import ConfirmationModal from "../components/ConfirmationModal";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const apiUrl = process.env.REACT_APP_API_URL;

const Author = () => {
  const { language } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [, setAuthorsIndexes] = useState({});
  const [categories, setCategories] = useState([]);

  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const [showAddModal, setShowAddModal] = useState(false);

  const [actionDescription, setActionDescription] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalAction, setConfirmModalAction] = useState(null);
  const [, setCategoryIndexes] = useState({});

  const password = localStorage.getItem("password");

  const imageInputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const categoriesUrl = `${apiUrl}/categories/${language}/`;
      try {
        const categoriesResponse = await axios.get(categoriesUrl, {
          headers: { "X-Password": password },
        });
        const categories = categoriesResponse.data;
        const res = {};
        setCategories(categories);
        setCategoryIndexes(res);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData().catch((error) => console.error("Error in fetchData:", error));
  }, [language, password]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const authorsResponse = await axios.get(
          `${apiUrl}/author/${language}.json`,
          {
            headers: { "X-Password": password },
          }
        );
        const fetchedAuthors = authorsResponse.data;
        const res = {};
        setAuthors(fetchedAuthors);
        setAuthorsIndexes(res);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData().catch((error) => console.error("Error in fetchData:", error));
  }, [language, password]);

  const handleAddAuthor = () => {
    setSelectedAuthor({});
    setShowAddModal(true);
  };

  const handleEditAuthor = (editedAuthor) => {
    setSelectedAuthor(editedAuthor);
    setShowEditModal(true);
  };

  const editAuthor = async (editedAuthor, updatedData) => {
    setIsLoading(true);
    try {
      await axios.put(
        `${apiUrl}/author/${language}/edit/${editedAuthor}`,
        updatedData,
        {
          headers: { "X-Password": password },
        }
      );
      setAuthors(
        authors.map((author) =>
          author.id === editedAuthor ? { ...author, ...updatedData } : author
        )
      );
      setIsLoading(false);
      setShowEditModal(false);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const updateSortData = async (sortData, selectedIndex) => {
    setIsLoading(true);
    try {
      await axios.put(
        `${apiUrl}/author/${language}/sort/${selectedIndex}`,
        sortData,
        {
          headers: { "X-Password": password },
        }
      );
      setIsLoading(false);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleConfirmationAction = (confirmedAction, actionDescription) => {
    setShowConfirmModal(true);
    setConfirmModalAction(() => confirmedAction);
    setActionDescription(actionDescription);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
  };

  const handleCheckboxChange = async (authorId, fieldKey, newValue) => {
    setIsLoading(true);
    await editAuthor(authorId, { [fieldKey]: newValue });
  };

  const handleDeleteAuthor = (authorId, authorName) => {
    handleConfirmationAction(async () => {
      try {
        await axios.delete(`${apiUrl}/author/${language}/${authorId}`, {
          headers: { "X-Password": password },
        });
        setAuthors(authors.filter((author) => author.id !== authorId));
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    }, `delete ${authorName}`);
  };

  const createNewAuthor = async (newAuthorData) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/author/${language}/add`,
        {
          name: newAuthorData.name,
          category: newAuthorData.category,
          free: newAuthorData.free,
          image: newAuthorData.image,
        },
        {
          headers: { "X-Password": password },
        }
      );
      const newAuthorId = response.data.id;
      let newAuthor = {
        id: newAuthorId,
        name: newAuthorData.name,
        category: newAuthorData.category,
        free: newAuthorData.free,
        image: newAuthorData.image,
      };
      setAuthors([...authors, newAuthor]);
      setIsLoading(false);
      setShowEditModal(false);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <div>
      {isLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      {authors.length > 0 && (
        <AuthorTable
          authors={authors}
          categories={categories}
          setAuthors={setAuthors}
          onEditAuthor={handleEditAuthor}
          onCreateAuthor={createNewAuthor}
          onDeleteAuthor={handleDeleteAuthor}
          updateSortData={updateSortData}
          onCheckboxChange={handleCheckboxChange}
        />
      )}
      {selectedAuthor && (
        <AuthorEditModal
          author={selectedAuthor}
          onEditAuthor={editAuthor}
          show={showEditModal}
          onClose={handleCloseModal}
          imageInputRef={imageInputRef}
        />
      )}
      {showAddModal && (
        <AuthorAddModal
          author={{}}
          onCreateAuthor={createNewAuthor}
          show={showAddModal}
          onClose={() => setShowAddModal(false)}
          imageInputRef={imageInputRef}
        />
      )}
      <ConfirmationModal
        show={showConfirmModal}
        action={confirmModalAction}
        actionDescription={actionDescription}
        onConfirm={() => setConfirmModalAction(null)}
        onClose={() => setShowConfirmModal(false)}
      />
      <Button
        variant="primary"
        className="save-button"
        onClick={handleAddAuthor}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </div>
  );
};

export default Author;
