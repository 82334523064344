import React, { useEffect, useState } from "react";
import CategoryTable from "../components/CategoryTable";
import CategoryEditModal from "../components/CategoryEditModal";
import CategoryAddModal from "../components/CategoryAddModal";
import { useParams } from "react-router-dom";
import axios from "axios";
import ConfirmationModal from "../components/ConfirmationModal";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const apiUrl = process.env.REACT_APP_API_URL;

const Category = () => {
  const { language } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [categories, setCategories] = useState([]);
  const [categoryIndexes, setCategoryIndexes] = useState({});

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [actionDescription, setActionDescription] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalAction, setConfirmModalAction] = useState(null);

  const password = localStorage.getItem("password");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const categoriesUrl = `${apiUrl}/categories/${language}/`;
      try {
        const categoriesResponse = await axios.get(categoriesUrl, {
          headers: { "X-Password": password },
        });
        const categories = categoriesResponse.data;
        const res = {};
        setCategories(categories);
        setCategoryIndexes(res);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData().catch((error) => console.error("Error in fetchData:", error));
  }, [language, password]);

  const handleEditCategory = (jsonData) => {
    setSelectedCategory(jsonData);
    setShowEditModal(true);
  };

  const handleAddCategory = () => {
    setSelectedCategory({}); 
    setShowAddModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
  };

  const handleSaveCategory = async (editedCategory) => {
    setIsLoading(true);

    const data = {};
    Object.entries(editedCategory).forEach(([key, value]) => {
      if (key === "name" || key === "id") {
        return;
      }
      data[key] = value;
    });
    editedCategory.new_name = editedCategory.name;
    await sendRequest(editedCategory.id, editedCategory);
  };

  const sendRequest = async (editedCategory, formData) => {
    try {
      await axios.put(
        `${apiUrl}/categories/${language}/edit/${editedCategory}`,
        formData,
        {
          headers: { "X-Password": password },
        }
      );
      setCategories(
        categories.map((category) => {
          if (category.id === editedCategory) {
            return {
              ...category,
              ...formData,
            };
          }
          return category;
        })
      );

      setSelectedCategory(null);
      setShowEditModal(false);
      setIsLoading(false);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const updateSortData = async (sortData, selectedIndex) => {
    setIsLoading(true);
    try {
      await axios.put(
        `${apiUrl}/categories/${language}/sort/${selectedIndex}`,
        sortData,
        {
          headers: { "X-Password": password },
        }
      );
      setIsLoading(false);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleConfirmationAction = (confirmedAction, actionDescription) => {
    setShowConfirmModal(true);
    setConfirmModalAction(() => confirmedAction);
    setActionDescription(actionDescription);
  };

  const handleDeleteCategory = (categoryId, categoryName) => {
    handleConfirmationAction(async () => {
      try {
        await axios.delete(`${apiUrl}/categories/${language}/${categoryId}`, {
          headers: { "X-Password": password },
        });
        setCategories(
          categories.filter((category) => category.id !== categoryId)
        );
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    }, `delete ${categoryName}`);
  };

const createNewCategory = async (newCategoryData) => {
  setIsLoading(true);
  try {
    const response = await axios.post(
      `${apiUrl}/categories/${language}/add`,
      newCategoryData,
      {
        headers: { "X-Password": password },
      }
    );
    const newCategoryId = response.data.id;
    let newCategory = {
      id: newCategoryId,
      ...newCategoryData,
    };
    setCategories([...categories, newCategory]);
    setIsLoading(false);
    setShowAddModal(false); 
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

  return (
    <div>
      {isLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      {categories.length > 0 && (
        <CategoryTable
          categories={categories}
          setCategories={setCategories}
          categoryIndexes={categoryIndexes}
          onEditCategory={handleEditCategory}
          onDeleteCategory={handleDeleteCategory}
          updateSortData={updateSortData}
        />
      )}
      {selectedCategory && (
        <CategoryEditModal
          category={selectedCategory}
          onSaveCategory={handleSaveCategory}
          show={showEditModal}
          onClose={handleCloseModal}
          categoryIndexes={categoryIndexes}
        />
      )}
      {showAddModal && (
        <CategoryAddModal
          category={{}}
          onCreateCategory={createNewCategory}
          show={showAddModal}
          onClose={() => setShowAddModal(false)}
        />
      )}
      <ConfirmationModal
        show={showConfirmModal}
        action={confirmModalAction}
        actionDescription={actionDescription}
        onConfirm={() => setConfirmModalAction(null)}
        onClose={() => setShowConfirmModal(false)}
      />
      <Button
        variant="primary"
        className="save-button"
        onClick={handleAddCategory}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </div>
  );
};

export default Category;
