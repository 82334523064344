import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const CategoryTable = ({
  categories,
  setCategories,
  onEditCategory,
  onDeleteCategory,
  updateSortData,
}) => {
  const [selectedIndex] = useState("01");
  const [sortedCategories, setSortedCategories] = useState([]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      const updatedSortedCategories = categories.slice().sort((a, b) => {
        const aValue = a[`sort_${selectedIndex}`] || 0;
        const bValue = b[`sort_${selectedIndex}`] || 0;
        return aValue - bValue;
      });
      setSortedCategories(updatedSortedCategories);
    }
  }, [categories, selectedIndex]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(sortedCategories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newCategories = items.map((category, index) => {
      return { ...category, [`sort_${selectedIndex}`]: index };
    });

    setCategories(newCategories);
    updateSortData(
      newCategories.reduce((acc, category) => {
        acc[category.id] = category[`sort_${selectedIndex}`];
        return acc;
      }, {}),
      selectedIndex
    );
  };

  return (
    <div className="container mt-5">
      <div className="table-responsive">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="categories">
            {(provided) => (
              <table
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="table table-bordered border table-striped table-hover mt-4"
              >
                <thead>
                  <tr>
                    <th className="text-center">Name</th>
                    <th className="text-center actions-column">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedCategories.map((category, index) => (
                    <Draggable
                      key={category.id}
                      draggableId={category.id}
                      index={index}
                    >
                      {(provided) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={category.id}
                        >
                          <td>{category.name}</td>
                          <td className="text-center">
                            <FontAwesomeIcon
                              className="text-secondary mr-2"
                              icon={faEdit}
                              onClick={() => onEditCategory(category)}
                            />
                            <FontAwesomeIcon
                              className="text-secondary"
                              icon={faTrash}
                              onClick={() =>
                                onDeleteCategory(category.id, category.name)
                              }
                            />
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default CategoryTable;
