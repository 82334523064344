import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Authors from "./pages/Author";
import Texts from "./pages/Texts";
import Categories from "./pages/Categories";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import AuthWrapper from "./components/AuthWrapper";

// const publicUrl = process.env.PUBLIC_URL;

const App = () => {
  const [language, setLanguage] = React.useState("en");

  useEffect(() => {
    const languageParam = window.location.hash.split("/")[1];
    if (languageParam) {
      setLanguage(languageParam);
    }
  }, []);

  return (
    <Router>
      <Navbar language={language} setLanguage={setLanguage} />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Navigate to={`/${language}/categories`} state={{ language }} />
          }
        />
        <Route
          path="/:language/categories"
          element={<Categories language={language} />}
        />
        <Route
          path="/:language/authors"
          element={<Authors language={language} />}
        />
        <Route
          path="/:language/texts"
          element={<Texts language={language} />}
        />
      </Routes>
    </Router>
  );
};

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <AuthWrapper>
    <App />
  </AuthWrapper>
);
