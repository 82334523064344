import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { LANGUAGES } from "../utils/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { matchPath } from "react-router";
import { useState } from "react";


const NavbarComponent = () => {
  const [language, setLanguage] = useState('en');
  const navigate = useNavigate();
  const location = useLocation();
  const match = matchPath("/:language/:tab", location.pathname);

const handleLanguageChange = (eventKey) => {
  setLanguage(eventKey);
  if (match && match.pathname) {
    const newUrl = `/${eventKey}${match.pathname.replace(`/${language}`, "")}`;
    navigate(newUrl);
  } else {
    navigate(`/${eventKey}`);
  }
};


  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand>HeroScope</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown
              title="Languages"
              id="basic-nav-dropdown"
              onSelect={handleLanguageChange}
            >
              {LANGUAGES.map((c) => (
                <NavDropdown.Item key={c.code} eventKey={c.code}>
                  {c.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <Nav.Item>
              <Link to={`/${language}/categories`} className="nav-link">
                Categories
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to={`/${language}/authors`} className="nav-link">
                Authors
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to={`/${language}/texts`} className="nav-link">
                Texts
              </Link>
            </Nav.Item>
          </Nav>
          <div className="ml-auto text-light" >{language}</div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
