import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Table, Toast } from "react-bootstrap";

const Texts = ({ language }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [isLoading, setIsLoading] = useState(false);
  const [filenames, setFilenames] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [jsonContent, setJsonContent] = useState({});
  const [toast, setToast] = useState({
    show: false,
    message: "",
    variant: "success",
  });

  const password = localStorage.getItem("password");

  useEffect(() => {
    const fetchFileNames = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/texts`, {
          headers: { "X-Password": password },
        });

        if (response.data.success && Array.isArray(response.data.files)) {
          setFilenames(response.data.files);
        } else {
          console.error("Invalid response format:", response.data);
          setFilenames([]);
        }
      } catch (error) {
        console.error("Error fetching filenames:", error);
        setFilenames([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFileNames().catch((error) =>
      console.error("Error in fetchData:", error)
    );
  }, [language, apiUrl, password]);

  const handleFileChange = async (e) => {
    const selectedFilename = e.target.value;
    setSelectedFile(selectedFilename);
    if (selectedFilename !== "Choose...") {
      setIsLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/texts/${selectedFilename}`, {
          headers: { "X-Password": password },
        });
        if (response.data.success) {
          setJsonContent(response.data.fileData);
        } else {
          setJsonContent({});
        }
      } catch (error) {
        console.error("Error fetching text content:", error);
        setJsonContent({});
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleContentChange = (key, value) => {
    setJsonContent((prevJsonContent) => ({
      ...prevJsonContent,
      [key]: value,
    }));
  };

  const handleCloseToast = () => {
    setToast({ ...toast, show: false });
  };

  const saveFile = async () => {
    try {
      await axios.put(
        `${apiUrl}/texts/${selectedFile}`,
        jsonContent,
        {
          headers: { "X-Password": password },
        }
      );
      setToast({
        show: true,
        message: "File saved successfully",
        variant: "success",
      });
    } catch (error) {
      console.error("Error saving file:", error);
      setToast({ show: true, message: "Error saving file", variant: "danger" });
    }
  };

  return (
    <div className="container mt-5">
      {isLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <Form>
        <Form.Group controlId="fileSelect">
          <Form.Control
            as="select"
            value={selectedFile}
            onChange={handleFileChange}
          >
            <option>Choose...</option>
            {filenames.map((filename, index) => (
              <option key={index}>{filename}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form>
      {selectedFile !== "" && (
        <>
          <Table striped bordered hover className="mt-5">
            <tbody>
              {Object.entries(jsonContent).map(([key, value], index) => (
                <tr key={index}>
                  <td className="key-column">{key}</td>
                  <td>
                    <Form.Control
                      type="text"
                      value={value}
                      onChange={(e) => handleContentChange(key, e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button className="save-button" onClick={saveFile}>
            Save
          </Button>
        </>
      )}
      <Toast
        onClose={handleCloseToast}
        show={toast.show}
        delay={3000}
        autohide
        style={{
          position: "fixed",
          top: 20,
          right: 20,
          minWidth: "200px",
        }}
      >
        <Toast.Header>
          <strong className={`me-auto text-${toast.variant}`}>
            Notification
          </strong>
        </Toast.Header>
        <Toast.Body>{toast.message}</Toast.Body>
      </Toast>
    </div>
  );
};

export default Texts;
